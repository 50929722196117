import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBNavbarNav, MDBNavbarLink, MDBNavbar, } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
export default function Footer() {  
  return (
    <MDBFooter className='bg-ui-dark text-white text-center text-lg-left'>
      <div className='p-4 container-xxl'>
        <MDBRow center >      
         <MDBCol xl='8' lg='12' className='mt-2 mb-md-0'>
          <MDBNavbar ria-label="footerNav" class='d-flex text-white align-items-center mb-0 footerNav '>
          <MDBNavbarNav  class='d-flex text-white align-items-center'>
                <MDBNavbarLink aria-current='page' href='/home'> Home </MDBNavbarLink>
            <MDBNavbarLink  aria-current="page" href="/businesslines">Businesslines</MDBNavbarLink>
                <MDBNavbarLink  aria-current="page" activeClassName="active" href="/company">Company</MDBNavbarLink>
                <MDBNavbarLink  aria-current="page" activeClassName="active" href="/solutions">Solutions</MDBNavbarLink>
                <MDBNavbarLink  aria-current="page" activeClassName="active" href="/support">Service & Support</MDBNavbarLink>
          </MDBNavbarNav>
          </MDBNavbar>         
        </MDBCol>
          <MDBCol lg='4' md='6' className='mb-4 mb-md-0 social-links'>
          <MDBBtn className='m-1' to='' style={{ backgroundColor: 'rgba(0, 197, 223, 0.66)' , padding: '0.8rem 0.8rem 0.6rem' }} size='md' href='tel:09769014645/'>
            <MDBIcon fas icon='phone' size='2x'/>
          </MDBBtn>
          <MDBBtn  className='m-1' style={{ backgroundColor: 'rgba(0, 197, 223, 0.66)' , padding: '0.7rem 0.8rem 0.5rem' }} size='lg' target='blank' href="https://wa.me/09769014645/?text=HI%2C%20Got%20Reference%20from%20your%20website.%20%20I%20will%20Like%20to%20know%20more%20about%20ADVA-HAENNI%20products%20and%20services.">
            <MDBIcon fab icon='whatsapp' size='2x'/>
          </MDBBtn>
          <MDBBtn  className='m-1' href="https://www.linkedin.com/in/adva-haenni-engineers-pvt-ltd-pune-452925128/" target='blank' style={{ backgroundColor: 'rgba(0, 197, 223, 0.66)', padding: '0.7rem 0.8rem 0.5rem' }} size='lg'>
            <MDBIcon fab icon='linkedin' size='2x'/>
            </MDBBtn>
            
          <MDBBtn  className='m-1' href="https://www.facebook.com/profile.php?id=100083925310299" target='blank' style={{ backgroundColor: 'rgba(0, 197, 223, 0.66)' , padding: '0.7rem 1.1rem 0.5rem' }} size='lg'>
            <MDBIcon fab icon='facebook-f' size='2x' />
          </MDBBtn>
          </MDBCol>
        </MDBRow>
      </div>

      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        &copy; {new Date().getFullYear()} Copyright:{' '}
        <a className='text-white' to='/'>
        ADVAHAENNI.COM
        </a>
      </div>
    </MDBFooter>
  );
}