import React, { useState, useEffect } from 'react';
  import {
    MDBNavbar,
    MDBContainer,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBCollapse,
    MDBBtn,
    MDBIcon,
    MDBNavbarNav,
  } from 'mdb-react-ui-kit';
  import {NavLink} from'react-router-dom';
  import LogoSmall from 'src/assets/images/logo-colurw-300.png';
  import EnquiryModal from './EnquiryModal';

const Navbar = () =>{
  const [showNavNoTogglerSecond, setShowNavNoTogglerSecond] = useState(false);
  
  const [centredModal, setCentredModal] = useState(false);
  const handelMenuClick = () => {
    window.scrollTo(0, 0);
    setShowNavNoTogglerSecond(false); 
  }
  useEffect(() => {
            window.addEventListener('scroll', isSticky);
            window.addEventListener('click', InputState )
    return () => {
              window.removeEventListener('scroll', isSticky);
              window.removeEventListener('click', InputState);
            };
        });
        const InputState = (e) => {
          document.querySelectorAll('.ui_form .form-control' ).forEach(function (item) {
            if (item.value.length < 0)
              item.classList.remove('active');
              else if (item.value.length > 0){
                item.classList.add('active');
                }
          });
  };

               
        /* Method that will fix header after a specific scrollable */
               const isSticky = (e) => {
                    const Navbar = document.querySelector('.header-section');
                 const NavWraper = document.querySelector('.sticky-wraper');
                 const PageWraper = document.querySelector('.page-content');
                    const scrollTop = window.scrollY;
                 scrollTop >= 200 ? (Navbar.classList.add('fixed-top'), PageWraper.classList.add('mb-scroll') ):( Navbar.classList.remove('fixed-top'), PageWraper.classList.remove('mb-scroll'));
                 document.querySelectorAll('.ui_form .active').forEach(function (item) {
                   item.classList.remove('active');                   
                 });
                 
                }; 
  

  const toggleShow = () => setCentredModal(!centredModal);    
  
  return (
    <>
      <EnquiryModal show={centredModal} setShow={setCentredModal} toggleShow= {toggleShow} ></EnquiryModal>
      <MDBNavbar expand='lg' dark className="justify-content-start align-items-md-start shadow-0 header-section">
        <MDBContainer breakpoint="xl" className="mainNav mt-md-0">         
          <MDBNavbarBrand className="navbar-brand justify-content-sm-center me-auto" href='/'>
          <img src={LogoSmall} height={50} mode='fit' alt="ADVA-HAENNI"/>
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type='button'
            data-target='#navbarTogglerDemo02'
            aria-controls='navbarTogglerDemo02'
            aria-expanded='false'
            aria-label='Toggle navigation'
            className='ms-auto ui_text_primary'
            onClick={() => setShowNavNoTogglerSecond(!showNavNoTogglerSecond)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>
          <MDBCollapse navbar show={showNavNoTogglerSecond}>
            <MDBNavbarNav className='me-lg-auto mb-2 w-auto mb-lg-0'>
              <MDBNavbarItem>
                <NavLink exact activeClassName="active" onClick={handelMenuClick} className="nav-link"  to='/home'>Home</NavLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <NavLink activeClassName="active" className="nav-link" onClick={handelMenuClick} to='/businesslines'>Businesslines</NavLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <NavLink activeClassName="active" className="nav-link" onClick={handelMenuClick} to='/company'>Company</NavLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <NavLink activeClassName="active" className="nav-link" onClick={handelMenuClick} to='/solutions'>Solutions</NavLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <NavLink activeClassName="active" className="nav-link" onClick={handelMenuClick} to='/support'>Service & Support</NavLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
            <MDBBtn className='rounded-0 action-btn ui_bg_highlight ms-md-3' onClick={toggleShow}  type='button'>Enquire Now</MDBBtn>            
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
}
export default Navbar;