import React, { useState } from 'react';
import {
  MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBRow, MDBCol, MDBBtn, MDBTextArea,
  MDBContainer, MDBIcon, MDBTypography, MDBInput, MDBValidationItem, MDBValidation} from 'mdb-react-ui-kit';

import './support.css';
import EnquiryForm from './EnquiryForm';
export default function Support() {

  return (
    <MDBContainer fluid className="Contact-container">
      <MDBContainer size="xxl">
      <MDBCard  background='assent' className='text-white my-3' >
        <MDBCardBody>
          <MDBCardTitle className='display-6 text-center mt-3' >We Are Here to Answer All Your Queries</MDBCardTitle>
          <MDBCardText variant='h6' className='text-center'>
         Contact us with the details bellow.
            </MDBCardText>
          <hr className="my-2" />
          <div>
              <div center className='d-flex justify-content-md-center mb-3 g-3 gap-3 align-items-md-stretch flex-wrap connect-box' >                
                <div className='d-flex gap-3 align-items-center justify-content-around box'>
                <div className='d-md-flex gap-3 align-items-center justify-content-start'>
                  <div className="rounded-circle icon-circle shadow-2-strong flex-shrink-0">
                  <i className="bi bi-geo-alt text-center"></i>
                  </div>  
                  <MDBTypography tag='div' variant='h5' className='mb-0 text-info ' >
                    Registered Office:<br></br>
                    <MDBTypography tag='p' variant='small' className='text-light mt-2 text-capitalize'>
                    14/1 Konark Aangan, Bibwewadi, <br/>Pune 411037 INDIA
                    </MDBTypography>
                    </MDBTypography>
                    </div>
                  <MDBTypography tag='div' variant='h5' className='mb-0 text-info'>
                  Workshop:<br></br>
                    <MDBTypography tag='p' variant='small' className='text-light mt-2 text-capitalize '>
                    62/2 Chintamani Industrial Estate, <br></br>Bhidebaug Vadgaon BK. PUNE 41
                    </MDBTypography>                   
                  </MDBTypography>                  
              </div>                
                <div className='d-flex gap-3 align-items-center justify-content-around box'>
                <div className='d-md-flex gap-3 align-items-center justify-content-md-start justify-content-sm-center'>
                <div className="rounded-circle d-md-inline-block icon-circle shadow-2-strong flex-shrink-0">
                  <i className="bi bi-chat-dots"></i>
                  </div>  
                  <MDBTypography tag='div' variant='h5'  className='mb-0 text-info '>
                      Call US:<br></br>
                      <MDBTypography tag='p' variant='small' className='text-light text-capitalize mt-2 mb-1 '>
                        <a href='tel:09769014645/' target='blank'> +91 9769014645 </a>
                      </MDBTypography>
                          
                    <MDBTypography tag='p'variant='small' className='text-light text-capitalize mb-0 '>
                    <a href='tel:09769014645/' target='blank'> +91 8551979160</a>
                        </MDBTypography>  
                  
                    </MDBTypography>
                  </div>
                  <MDBTypography tag='div' variant='h5'  className='mb-0 text-info'>
                    Write to US:<br></br>
                    <MDBTypography tag='p'variant='small' className='text-light mt-2 mb-1 '>
                    <a href='mailto:support@adva-haenni.com' target='blank'>support@adva-haenni.com</a>
                    </MDBTypography>
                    <MDBTypography tag='p'variant='small' className='text-light  mb-0 '>
                    <a href='mailto:qc@adva-haenni.com' target='blank'>qc@adva-haenni.com</a>
                    </MDBTypography>                    
                  </MDBTypography>
                </div>  
                
            </div>
            </div>
            <hr className="mt-2 mb-4" />
            
            <MDBContainer>
              <MDBRow className='align-items-center'>
                <MDBCol lg='6' md='12'>
                <div className="ratio ratio-4x3">
                <iframe className="rounded-3" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.520452198923!2d73.81433231530765!3d18.460070075838747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2956abc9f1a3f%3A0x8f27a754d25c941a!2sADVA%20HAENNI%20ENGINEERS%20PVT%20LTD%20PUNE!5e0!3m2!1sen!2sin!4v1657810420004!5m2!1sen!2sin" allowFullScreen ></iframe>
                </div>
                </MDBCol>
                <MDBCol lg='6' md='12'>
                  <MDBTypography tag='div' variant='h4' className='text-light text-capitalize text-center mb-4 mt-md-3'>
                    Looking For A Custom Made Component?  
                    <MDBTypography tag='small' className='ms-2 ui_text_highlight'>
                      Write to Us
                    </MDBTypography>
                    </MDBTypography>
                 <EnquiryForm></EnquiryForm>
                </MDBCol>
              </MDBRow>
              
            </MDBContainer>
            <hr className="mt-2 mb-4" />           
        </MDBCardBody>
      </MDBCard>
      </MDBContainer>
    
  </MDBContainer>
  );
}