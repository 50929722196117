import React, {useState} from 'react';
import {MDBTypography, MDBBtn,  MDBModal,  MDBModalDialog,  MDBModalContent,  MDBModalHeader,
    MDBModalTitle, MDBModalBody, MDBModalFooter, MDBContainer, MDBRow, MDBCol,
} from 'mdb-react-ui-kit';
import EnquiryForm from '../support/EnquiryForm';
  
const EnquiryModal = (props) => {
  const { show, setShow, toggleShow } = props
  
    return (
        <MDBModal  tabIndex='-1' show={show} setShow={setShow} className='EnquiryModal'>
        <MDBModalDialog centered size='lg'>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Send Your Enquiry Details</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className='px-2 py-4'>
            <EnquiryForm></EnquiryForm>
            </MDBModalBody>
            
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );

}

export default EnquiryModal


