import React from 'react';
import {MDBContainer, MDBTypography, MDBRow, MDBCol  } from 'mdb-react-ui-kit';
import Navbar from './Navbar';
import LogoBig from 'src/assets/images/logo-colurh-80px.png'
export default function Header() {
  
  return (    
    <header>
    <MDBContainer fluid className="topBar">
      <div className="container-xxl d-flex ">
        <a className="icon d-flex align-items-center" href='https://goo.gl/maps/6w5AXYYtVCpFijUh7' target='blank'><i className="fas fa-map-marked-alt mr-2" aria-hidden="true"></i> 
        <p className="mb-0 ms-2 text-capitalize">PLOT NO 62/2 Chintamani industrial estate Bhidebaug Vadgaon BK. PUNE 411041</p>
        </a>
        <div className="social ms-auto">
            <a className="icon" href="https://www.facebook.com/profile.php?id=100083925310299"  target="_blank"><i className="fab fa-facebook-square" aria-hidden="true"></i></a>
            <a className="icon" href="https://www.linkedin.com/in/adva-haenni-engineers-pvt-ltd-pune-452925128/"  target="_blank"> <i className="fab fa-linkedin" aria-hidden="true"></i></a>
            <a  className="icon" target='blank' href="https://wa.me/09769014645/?text=HI%2C%20Got%20Reference%20from%20your%20website.%20%20I%20will%20Like%20to%20know%20more%20about%20ADVA-HAENNI%20products%20and%20services."> <i className="fab fa-whatsapp font-weight-bold" aria-hidden="true"></i></a>
        </div>    
    </div>
    
    </MDBContainer>
    <MDBContainer fluid className="header header-wraper">
      <MDBContainer breakpoint="xl">
    <MDBRow end>   
        <MDBCol size='12' md="auto" lg="2" className='me-auto'>
        <div className="navbar-brand justify-content-sm-center">
          <img src={LogoBig} className="img-fluid logo-big" alt="ADVA-HAENNI"/></div>
        </MDBCol>

        <MDBCol size='6'  md="4" lg="auto"  className='d-none d-md-flex '>
        <div className="d-flex hed-connect align-items-center justify-content-end flex-fill">
              <div className="icon">
                <i className="bi bi-chat-dots text-info me-3" aria-hidden="true"></i>
              </div>
              < div className="ms-4 ms-md-0">
                  <div className="text-danger">Get in Touch</div>
                  
                    <a href='tel:09769014645/' className="text-dark detail mb-0 pb-0 d-block"  target='blank' > 0091-9769014645 </a>
                    <a href='mailto:support@adva-haenni.com' className="text-dark pt-0 mt-0 detail" target='blank' > support@adva-haenni.com</a>
              </div> 
          </div>
          </MDBCol>
          <MDBCol size='6'  md="auto" lg="auto"  className='col-example d-none d-md-flex '>
          <div className="hours d-flex align-items-center justify-content-end flex-fill">
              <div className="icon d-md-flex d-none">
                <i className="bi bi-clock text-info me-3" aria-hidden="true"></i>
              </div>
              <div>
              <div className="text-danger">Working Hours</div>
              <div className="text-dark detail">09.00 am to 6.00 pm <br/>(weekly Off- sunday)</div>
              </div>
          </div>
        </MDBCol>
      </MDBRow>
      </MDBContainer>
    </MDBContainer>
      <Navbar />
      
    </header>
  );
}