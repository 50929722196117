
export const FeaturedCards = [
  {
  id: 1,
  imageSrc: "assets/images/home/fp_img-1.jpg",
  title: 'Product Manufacturing',
  description: 'Measurement Range: 1.6 meter to 2.5 meters',
  _link: '/products/product-manufacturing',
    get link() {
        return this._link;
    },
    set link(value) {
        this._link = value;
    },
    sectors: ['Level Measurements', 'Process Instruments'],
},
{ 
  id: 2,
  imageSrc:"assets/images/home/fp_img-2.jpg",
    title: 'Brass Components',
    description: 'Measurement Range : as per Customer design',
    _link: '/products/accessories',
    get link() {
        return this._link;
    },
    set link(value) {
        this._link = value;
    },
    sectors: ['Process Instruments', 'Special Refineries'],
},
{
  id: 3,
  imageSrc:"/assets/images/home/fp_img-3.jpg",
    title: 'Steel Components',
    description: 'Measurement Range: as per DIN or ANSI',
    _link: '/products/machined-components',
    get link() {
        return this._link;
    },
    set link(value) {
        this._link_3 = value;
    },
    sectors: ['Pressure Gauges', 'Pressure Transmitters'],
},
{
  id: 4,
  imageSrc:"/assets/images/home/fp_img-4.jpg",
    title: 'Sheet Metal Parts',
    description: 'stainless Steel Sheet Metal Parts made as per customer design',
    _link: '/products/sheetmetal-parts',
    get link() {
        return this._link;
    },
    set link(value) {
        this._link = value;
    },
    sectors: ['Factory automation', 'Gauges'],
},
{
  id: 5,
  imageSrc:"/assets/images/home/fp_img-5.jpg",
    title: 'Customized Components',
    description: 'Customized Components made as per your needs',
    _link: '/businesslines',
    get link() {
        return this._link;
    },
    set link(value) {
        this._link = value;
    },
    sectors: ['Process Instruments', ' Food Industry'],
},
];

export const SectorsList = [
  {
      id: 1,
      icon:'precision_manufacturing ',
      title: 'Process Instruments',
      linksrc:'/solutions',
    },
    {
      id: 2,
      icon:'',
      title: 'Sensors',
      linksrc:'/solutions',
      bicon: true,
    },
    {
      id: 3,
      icon:'vaccines',
      title: 'Pharma',
      linksrc:'/solutions',
    },
    {
      id: 4,
      icon:'soup_kitchen',
      title: 'Food',
      linksrc:'/solutions',
    },
    {
      id: 5,
      icon:'emoji_transportation',
      title: 'Transport',
      linksrc:'/solutions',
    },
    {
      id: 6,
      icon:'agriculture',
      title: 'Heavy vehicles',
      linksrc:'/solutions',
    },
];
export const CustomerList = [
{
    id: 1,
    VcLogo:"assets/images/home/vc-logo-1.jpg",
    title: 'Haenni',
    linksrc:'/',
  },
  {
    id: 2,
    VcLogo:"assets/images/home/vc-logo-2.jpg",
    title: 'Baumer',
  },
  {
    id: 3,
    VcLogo:"assets/images/home/vc-logo-3.jpg",
    title: 'KHW',
  },
  {
    id: 4,
    VcLogo:"assets/images/home/vc-logo-4.jpg",
    title: 'Bourdon',
    linksrc:'/',
  },
  {
    id: 5,
    VcLogo:"assets/images/home/vc-logo-5.jpg",
    title: 'JKTyre',
    linksrc:'/',
  },
  {
    id: 6,
    VcLogo:"assets/images/home/vc-logo-6.jpg",
    title: 'Michelin',
    linksrc:'/',
  },
  {
    id: 7,
    VcLogo:"assets/images/home/vc-logo-7.jpg",
    title: 'CSIR',
    linksrc:'/',
  },
  {
    id: 8,
    VcLogo:"assets/images/home/vc-logo-8.jpg",
    title: 'Bridgestone',
    linksrc:'/',
  },
]