import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout =  ({ children, ...rest }) => {
    return (
      <div className='wraper'>
        <Header/>
        <section className="page-content">{children}</section>        
        <Footer/>
      </div>
    );
  };

export default Layout;
