export const AdvaProducts = [
    {
      name: 'Product Manufacturing',
      id: 'product-manufacturing',
      icon:'/assets/svg/bl-ico-1.svg',
      description: "We make complete Product as per customer design along with supply chain, assembly, testing & certifications ",
      url: '/products/product-manufacturing',
      single: true,
      pdf: '/assets/pdf/SDI439_01-87.pdf',
      resources: [
        {
          name: 'Level Gauge (Mechanical ) Model SDI',
          id: 'Level-Gauge',
          details: "Model SDI is Mechanical (Low Pressure Capsule ) Level Gauge for measuring Oil Level in Tank with up to 5 meter height.",
          Material:  ' steel and Brass',
          Application : 'Level Measurements',
          MeasurementRange:'1.6 meter to 2.5 meters',
          image: '/assets/images/products/c1/c1-product1.jpg',
          external:false,
        },
    ]
    },
    {
      name: 'Accessories',
      id: 'accessories',
      description: "We offer a varied range of accessories for various Process Instruments & Sensors.",
      url: 'products/accessories/',
      icon:'/assets/svg/bl-ico-2.svg',
      resources: [
        {
          name: 'Metal Bellows',
          id: 'metal-bellows',
          Material:  'SS316L',
          Application : 'Temperature Gauges (Mechanical)',
          MeasurementRange:'As per Customer requirements ',
          image: '/assets/images/products/c2/c2-product1.jpg',
          external:false,
          
        },
        {
            name: 'Welded  SS  Thermowells',
            id: 'welded-thermowells',
            Material:  'SS316L',
            Application : 'Power Plants & Refineries',
            MeasurementRange:'60 mm to 1000 mm Length',
            image: '/assets/images/products/c2/c2-product2.jpg',
            external:false,
        },
        {
            name: 'Hygienic  SS  Thermowells',
            id: 'higenic-thermowells',
            Material:  'SS316L',
            Application : 'Food & Beverage Industry',
            MeasurementRange:' As per Customer requirements ',
            image: '/assets/images/products/c2/c2-product3.jpg',
            external:false,
        },
        {
            name: 'Barstock Thermowells',
            id: 'barstock-thermowells',
            Material:  'SS304L /SS316L',
            Application : 'Power Plants & Refineries',
            MeasurementRange:' As per Customer requirements ',
            image: '/assets/images/products/c2/c2-product4.jpg',
            external:false,
        },
        {
            name: 'Hard Soldering Thermowells',
            id: 'hard-soldering-thermowells',
            Material:  'Brass + silver base soldering',
            Application : 'Special Refineries ',
            MeasurementRange:' As per Customer design ',
            image: '/assets/images/products/c2/c2-product5.jpg',
            external:false,
        },
        {
            name: 'Electro Polished Thermowells',
            id: 'electro-polished-thermowells',
            Material:  'special stainless steel',
            Application : 'Special application like Research Instruments.',
            MeasurementRange:'N.A.',
            image: '/assets/images/products/c2/c2-product6.jpg',
            external:false,
        },
        {
            name: 'Torque Arm',
            id: 'torque-arm',
            Material:  'SS304L /SS316L',
            Application : 'For Encoder hold',
            MeasurementRange:'160 mm to 400 mm',
            image: '/assets/images/products/c2/c2-product7.jpg',
            external:false,
        }
      ]
    },
    {
      name: 'Machined Components',
      id: 'machined-components',
      description: 'At ADVA-HAENNI, We make High Precision stainless steel Parts used for Process Instruments & Factory automation products.',
      url: '/products/machined-components',
      icon:'/assets/svg/bl-ico-3.svg',
      resources: [ 
                {
                    name: 'Pressure Transmitter Connectors',
                    id: 'pst-connectors',
                    Material: 'SS304L/SS316L',
                    Application :'Pressure Transmitters',
                    MeasurementRange:'All connections as per DIN or ANSI',
                    image: '/assets/images/products/c3/c3-product1.jpg',
                    external:false,
                },
                {
                    name: 'Pressure Transmitter Housing',
                    id: 'pst-housing',
                    Material:  ' SS304L/SS316L',
                    Application : 'Process Instruments',
                    MeasurementRange:'As per customer design ',
                    image: '/assets/images/products/c3/c3-product2.jpg',
                    external:false,
                
                },
        
                {
                    name: 'Temperature Transmitter Connectors',
                    id: 'tmt-connectors',
                    Material:  'SS304L/SS316L',
                    Application : ' Process Instruments.',
                    MeasurementRange:'As per customer design',
                    image: '/assets/images/products/c3/c3-product3.jpg',
                    external:false,
                },
                {
                    name: 'Temperature Transmitter Housing',
                    id: 'ttr-housing',
                    Material:  'SS304L/SS316L',
                    Application : 'Special application like Research Instruments.',
                    MeasurementRange:' As per customer design ',
                    image: '/assets/images/products/c3/c3-product4.jpg',
                    external:false,
                },

                {
                  name: 'DIN / ANSI Standard Components',
                  id: 'din-ansi',
                  Material:  'SS316L',
                  Application : 'Pressure Gauges/Transmitters',
                  MeasurementRange:' As per customer design ',
                    image: '/assets/images/products/c3/c3-product5.jpg',
                    external:false,
              
              },
      
              {
                  name: 'Temperature Gauges- SS Parts',
                  id: 'tmg-steel',
                  Material:  'SS304L/SS316L',
                  Application : 'Mechanical Temperature Gauges',
                  MeasurementRange:' As per customer design ',
                  image: '/assets/images/products/c3/c3-product6.jpg',
                  external:false,
              },
              {
                  name: 'Temperature Gauges- Brass Parts',
                  id: 'tmg-brass',
                  Material:  'Brass / CuZBPb39',
                  Application : 'Temperature Gauge Pockets',
                  MeasurementRange:' As per customer design ',
                  image: '/assets/images/products/c3/c3-product7.jpg',
                  external:false,
              },

              {
                name: 'Custom Made Parts',
                id: 'custom-parts',
                Material:  'stainless steel/Brass/Aluminum',
                Application : ' Process Instruments / Sensors',
                MeasurementRange:'As per customer design',
                  image: '/assets/images/products/c3/c3-product8.jpg',
                  external:false,
            },
       
      ]
    },
    {
        name: 'Sheet Metal Parts',
        id: 'sheetmetal-parts',
        description: 'We manufacture stainless steel Sheet Metal Parts for Process instruments.',
        url: '/products/sheetmetal-parts',
        icon:'/assets/svg/bl-ico-5.svg',
        resources: [ 
                  {
                      name: 'Housing For Pressure & Temperature Gauges',
                      id: 'pst-connectors',
                      Material:  'SS304L/SS316L',
                      Application : 'Pressure Gauges',
                      MeasurementRange:'As per customer design ',
                      image: '/assets/images/products/c4/c4-product1.jpg',
                      external:false,
                  },
                  {
                      name: 'Closures For Pressure & Temperature Gauges',
                      id: 'ptt-closures',
                      Material:  'SS304L/SS316L',
                      Application : 'Pressure Gauges',
                      MeasurementRange:' As per customer design ',
                      image: '/assets/images/products/c4/c4-product2.jpg',
                      external:false,
                  
                  }
         
        ]
      },
      {
        name: 'Haenni Products',
        id: 'haenni-products',
        description: 'SWISS make PORTABLE WHEEL LOAD SCALES (BRAND-HAENNI)',
        url: '/products/haenni-products',
        icon: '/assets/svg/bl-ico-4.svg',
        single: true,
        pdf: '/assets/pdf/HAENNI.pdf',
        resources: [ 
                  {
                      name: 'PORTABLE WHEEL LOAD SCALES',
                      id: 'haenni-scales',                      
            details: 'HAENNI is world Leader in the Business of PORTABLE WHEEL LOAD SCALE since 1974 & ADVA-HAENNI ENGINEERS PVT LTD is associated with them since 2015 catering INDIA & SRILANKA market .',
                      //the Portable Wheel Load scale used to measure weight of heavy vehicles . The Measure applications are Weight Enforcements , R&D , Ship Containers & Logistics Solutions Companies.
                      link:'https://www.haenni-scales.com',
                      image: '/assets/images/products/c5/c5-product1.jpg',
                      external:true,
                  }
         
        ]
      },
  ]