import React from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBRow, MDBCol, MDBBtn, MDBContainer, MDBCardImage } from 'mdb-react-ui-kit';
import HeroImg from 'src/assets/images/banners-large-5-image.jpg';
import Category1 from 'src/assets/images/products/category-img-2.jpg';
import Category2 from 'src/assets/images/products/category-img-2.jpg';
import Category3 from 'src/assets/images/products/category-img-3.jpg';
import Category4 from 'src/assets/images/products/category-img-4.jpg';

import { Link, useRouteMatch, useParams } from 'react-router-dom';

var ReactRotatingText = require('react-rotating-text');
const Businesslines = () =>{
  return (
    <>
    <MDBContainer fluid className='p-0 m-0'>
    <div  className='bg-image PageTital'>
    <img src={HeroImg} className='img-fluid' alt='Sample' />
      <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
        <div className='d-flex flex-column justify-content-center align-items-center h-100'>
          <h3 className='sub-text ui_text_coral'>Businesslines </h3>
        <h3 className='text-white ui_text_coral caption text-center'> We Manufacture .   
        <span className='ui_text_highlight'>  
         <ReactRotatingText cursor = {false} eraseMode ='overwrite' 
          items={[' Precision', ' Perfection', ' Quality']} />
        </span> .</h3>
        </div>
      </div>
      </div>
    </MDBContainer>
    <MDBContainer className="content-wraper pb-5">
      <MDBRow className='cardDeck-main g-5 align-items-lg-stretch'>
        <MDBCol md='12' lg='6' className='align-content-lg-stretch'>
        <MDBCard>
          <MDBCardImage src={"/assets/images/products/product-mfg-c1.png"} alt='...' fluid /> 
          <MDBCardBody className='d-flex flex-column align-self-stretch'>
            <MDBCardTitle className='ui_text_coral text-uppercase'>PRODUCT MANUFACTURING</MDBCardTitle>
            <MDBCardText className='mt-2'>
            It refers to the process of manufacturing of various goods for clients which are
             typically used for sale purposes.  At ADVA-HAENNI, we use top-quality machinery, 
            tools, and formulation to turn the raw material into a quality product.
            </MDBCardText>
            <MDBCardText className='mt-auto' >
              <small className='ui_text_light'>Process Instrumentation</small>
            </MDBCardText>
            <Link className='btn me-auto mt-auto ui_bg_primary rounded-0' to='/products/product-manufacturing'>Know More</Link>
          </MDBCardBody>
        </MDBCard>
        </MDBCol>
        <MDBCol md='12' lg='6' ><MDBCard>
          <MDBCardImage src={"/assets/images/products/Accessories-c2.png"} alt='...' fluid /> 
          <MDBCardBody className='d-flex flex-column align-self-stretch'>
            <MDBCardTitle className='ui_text_coral text-uppercase'>Accessories</MDBCardTitle>
            <MDBCardText className='mt-2'>
            We offer a varied range of accessories for  various process 
            Instruments &  sensors. We are proud of the fact that all our tools 
            perfectly match to our customer’s requirements as each of our product 
            is carefully crafted according to the needs of the clients and through 
            several quality checks.

            </MDBCardText>
            <MDBCardText className='mt-auto'>
              <small className='ui_text_light'>Process instrumentation & Factory automation</small>
            </MDBCardText>
            <Link className='btn me-auto ui_bg_primary rounded-0' to='products/accessories' >Know More</Link>
          </MDBCardBody>
        </MDBCard>
        </MDBCol>
        <MDBCol md='12' lg='6'>
          <MDBCard>
          <MDBCardImage src={"/assets/images/products/machined-parts-c3.png"} alt='...' fluid />
        
          <MDBCardBody className='d-flex flex-column align-self-stretch'>
            <MDBCardTitle className='ui_text_coral text-uppercase'>Machined Components</MDBCardTitle>
            <MDBCardText className='mt-2'>
            At ADVA-HAENNI, we offer a variety of machined parts for Process 
            Instruments & Sensors, with a highly responsible and manageable team, 
            that knows the nuances of machining. We assure you it will met in a 
            timely manner and quality deliverables.

            </MDBCardText>
            <MDBCardText className='mt-auto'>
              <small className='ui_text_light'>Food and Pharma</small>
            </MDBCardText>
            <Link className='btn me-auto ui_bg_primary rounded-0' to='products/machined-components'>Know More</Link>
          </MDBCardBody>
        
          </MDBCard>
        </MDBCol>
        <MDBCol md='12' lg='6' className='align-items-stretch'>
        <MDBCard className='h-100' >
        <MDBCardImage src={"/assets/images/products/sheet-metal-c4.png"} alt='...' fluid />  
       
          <MDBCardBody className='d-flex flex-column align-self-stretch'>
            <MDBCardTitle className='ui_text_coral text-uppercase'>Sheetmetal Parts</MDBCardTitle>
            <MDBCardText className='mt-2 mb-3'>
            We supply Stainless Steel Stamping Parts for Various Global Customers
             as per quality Norms & specifications.
            </MDBCardText>

            <MDBCardText className='mt-auto'>
              <small className='ui_text_light'>Factory automation</small>
            </MDBCardText>
            <Link className='btn me-auto ui_bg_primary rounded-0' to='products/sheetmetal-parts'>Know More</Link>
          </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol sm='12'>
        <MDBCard className='d-flex justify-content-levenly'>
        <MDBRow>
        <MDBCol md='7' className='d-flex align-items-stretch'>
        <MDBCardImage src={"/assets/images/products/haenni-c5.png"} alt='...' fluid />  
        </MDBCol>
        <MDBCol md='5'>
          <MDBCardBody className='d-flex flex-column align-self-stretch'>
            <MDBCardTitle className='ui_text_coral text-uppercase'>Haenni Products</MDBCardTitle>
            <MDBCardText className='mt-2'>
            We represent SWISS Company , HAENNI INSTRUMENTS INC for INDIA & SRILANKA market. <br/>
            We are responsible for Sale & after sale service for HAENNI Product , 
            we have Calibration & Repair set up at PUNE build as per Swiss Parameters.


            </MDBCardText>
            <MDBCardText className='mt-auto'>
              <small className='ui_text_light'>Heavy vehicle mfg, road & transport</small>
            </MDBCardText>
            <Link className='btn me-auto  ui_bg_primary rounded-0' to='products/haenni-products'>Know More</Link>
          </MDBCardBody>
          </MDBCol></MDBRow>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer></>
  );
}
export default Businesslines;