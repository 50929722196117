import React from 'react';
import { MDBCard, MDBCardImage, MDBCardTitle, MDBCardText, 
  MDBRow, MDBCol, MDBBtn, MDBContainer, MDBTypography } from 'mdb-react-ui-kit';
import './compeny-2.css';
import IntroImage from 'src/assets/images/about/machine.JPG';
import Team from 'src/assets/images/about/team2.jpg';
import ContactForm from '../layout/ContactForm';
import haenni from 'src/assets/images/vc-logo-1.jpg';
import baummer from 'src/assets/images/vc-logo-2.jpg';
import Certificate1 from 'src/assets/images/about/AGSI-Cer-1.jpg';
import Certificate2 from 'src/assets/images/about/AGSI-Cer-2.jpg';
var ReactRotatingText = require('react-rotating-text');

const Company = () =>{
  return (
    <> 
      
      <MDBContainer fluid className='intro-container px-0'>
      
        <div className='pt-5 mb-0 pb-4 px-0 mx-0 border-bottom border-gray' style={{ backgroundColor: 'rgb(11 19 29 / 80%)' }}>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <h3 className='sub-text ui_text_coral'>ADVA-HAENNI </h3>
        <h3 className='text-white ui_text_coral caption text-center'> We Are .    
        <span className='ui_text_highlight'>  
         <ReactRotatingText cursor = {false} eraseMode ='overwrite' deletingInterval ='20'
          items={[' Advanced', ' Dynamic', ' Versatile', 'Attentive']} />
        </span> .</h3>
        </div>
      </div>
      <MDBContainer fluid className='section px-2'>
      <div className="box mb-5">
        <div className='item align-content-lg-end pe-lg-3 mt-lg-5'>
            <img className="img-fluid float-end fix-img" src={IntroImage} alt=""></img>
            <img className="layring_shadow img-fluid float-img" src={Team} alt=""></img>
        </div>
          <div className='item px-3 pe-lg-5'>
          
        <MDBTypography variant='h3' className='ui_text_coral'>Our Journey</MDBTypography>
          <MDBTypography className='detail'>
          We Started Our Journey with a solid foundation of rich and diverse experience of 20 years, ADVA-HAENNI was established to create
          manufacturing products which will streamline operations in several industries and services and 
          give the desired results. With hands-on experience in top multinational manufacturing companies, 
          we are proud of the fact that we have the vision to craft solutions for the Global Customer 
          as per International Standards.
          </MDBTypography>
          <MDBTypography className='detail'>
          Our forte lies in the fact that we design the products with cost-effective solutions with 
          no compromise on quality. Along with that we have our own fully-managed CNC machine workshop 
          with quality checking set-up. 
          We follow our own QMS set-up for our own manufacturing as well as Supply Chain Management.
              </MDBTypography>      
              <MDBRow className='gy-4 mb-3'>
            <MDBCol className='d-flex gap-3 align-items-center box-highlight p-3' lg='12'>
                  <div className="rounded-circle icon-circle shadow-2-strong flex-shrink-0">
                  <i className="bi bi-eye"></i>
                  </div>  
                  <MDBTypography tag='div' variant='p' className='display-6 mb-0 text-info' >
                    Our Vision<br></br>
                    <MDBTypography tag='p' className='lead text-light text-capitalize mb-1'>
                    Understanding customer requirements thoroughly & crafting products which offer
                    customized and modern and time-saving solutions.
                    </MDBTypography>
                  </MDBTypography>                           
              </MDBCol> 
              <MDBCol className='d-flex gap-3 align-items-center box-highlight p-3' lg='12'>
                  <div className="rounded-circle icon-circle shadow-2-strong flex-shrink-0">
                  <i className="bi bi-graph-up-arrow"></i>
                  </div>  
                  <MDBTypography tag='div' variant='p' className='mb-0 text-info display-6' >
                    Our Mission<br></br>
                    <MDBTypography tag='p' className='lead text-light text-capitalize mb-1'>
                    Expanding our manufacturing set-up globally and meeting the needs of our esteemed
                    clients with perfection.
                    </MDBTypography>
                  </MDBTypography>                           
              </MDBCol> 
              </MDBRow>  
              <img className="img-fluid d-sm-block d-md-none" src={IntroImage} alt=""></img>  

            <MDBTypography variant='h3' className='ui_text_coral'>Our Team</MDBTypography>
            <MDBTypography className='lead'>
            The team at ADVA-HAENNI is in the relentless pursuit of crafting innovative, 
            avant-garde and skilled components. With our skills and expertise put to the optimum use, 
            we are confident of our capabilities as a manufacturing organization and in providing the 
            best for our esteemed customers.
              </MDBTypography> 
              <img className="img-fluid d-sm-block d-md-none" src={Team} alt=""></img> 
          </div>          
             
          </div>
      </MDBContainer>
      </MDBContainer>
      
      <MDBContainer size='xxl' className='pt-5 quality-section'>
        <MDBRow center middle className='pb-5'>
          <MDBCol md={3} sm={12}>
          {/* <h4 className='text-left ui_text_primary my-4'>We are AGSI Certified!</h4> */}
            <MDBCard className='rounded-2'>
              <MDBCardImage className="img-fluid rounded-2" src={Certificate1} alt=""/>
            </MDBCard>
            </MDBCol>
          <MDBCol md={6} sm={12}>
          <MDBTypography tag='div' className='text-left ui_text_primary h4 container my-2'>Quality Manufacturing is <span className='h3 ui_text_coral'> A System </span><br/> And  <span className='h3 ui_text_coral'>We Implement </span>It</MDBTypography>
          <MDBTypography tag='div' className='lead text-align-justify container w-responsive pt-1'> We are AGSI Certified Manufacturing Company! We have developed a Quality Management Service which aims to ensure that the quality of products or services meets -or exceeds-customer expectations.<br/>
We understand customer's accurate requirements and specifications of each part or product . We then implement n monitor  for each production and quality checking process .
<br/>We are committed to continual improvement of Quality Management System .</MDBTypography>
          </MDBCol>
          <MDBCol md={3} sm={12}>
          {/*<h4 className='text-left ui_text_primary my-4'>Our Quality Policy</h4>*/}
            <MDBCard className='rounded-2'>
              <MDBCardImage className="img-fluid rounded-2" src={Certificate2} alt=""/>
            </MDBCard>
          </MDBCol>
          </MDBRow>
         
      </MDBContainer>
      <MDBContainer fluid className='pb-5 border-top bg-info bg-opacity-25 py-4 my-0 shadow-2-strong'>
        <MDBRow center middle>
          <MDBCol lg={12} sm={12}>
     <MDBContainer breakpoint='xl' className='p-0 pt-3'>
      <MDBContainer className='bg-light mb-4 p-0 rounded-2 shadow-2-soft d-flex flex-column flex-md-row justify-content-between align-content-center  my-2'>
       <img className=" img-fluid m-4"sizes='(max-width 500px) 500px, 1000px' src={baummer} alt=""></img>   
       <MDBTypography tag='div' className='lead text-left container w-responsive pt-2 w-75 '>
                <h6 className='text-left my-3 text-capitalize text-dark'>We are happy  with supply & service from
                <br /> M/S ADVA HAENNI ENGINEERS PUNE in our various Product segments.   </h6>
                
                <p className='text-left ui_text_primary mt-2 mb-0'>Philippe Imhof <br />
                  Head - Process Instruments </p>
                  <p className='text-left ui_text_coral'>Baumer Group</p>
      </MDBTypography>         
            </MDBContainer>
            <MDBContainer className='bg-light mt-4 p-0 rounded-2 shadow-2-soft d-flex flex-md-row flex-column-reverse justify-content-between align-content-center my-2'>
               <MDBTypography tag='div' className='lead text-left container w-responsive pt-2 w-75 '>
                <h6 className='text-left my-3 text-capitalize text-dark'>
                  Thank you for your efforts for promoting HAENNI scales in India.<br />
                  We are happy to have a reliable and dedicated partner like ADVA-HAENNI ENGINEERS and
                  wish you and your team pleasure and success.
                </h6>
                
                <p className='text-left ui_text_primary mt-2 mb-0'>Kneubühl Daniel <br />
                Managing Director</p>
                  <p className='text-left ui_text_coral'>HAENNI Instruments Inc.</p>
              </MDBTypography>      
              <img className="img-fluid m-4" sizes='(max-width 400px) 400px, 700px' src={haenni} alt=""></img>          
     </MDBContainer>
  </MDBContainer>
          </MDBCol>
        </MDBRow> </MDBContainer>
  <section id='connect'>
    <ContactForm></ContactForm>
  </section>
  </>

  );
}
export default Company;