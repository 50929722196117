import * as React from 'react';
import { useState } from 'react';
import {
  BrowserRouter as Router,
  Link,
  NavLink,
  Route,
  useRouteMatch,
  useParams
} from 'react-router-dom';
import './products.css';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImage,
  MDBRow, MDBCol, MDBBtn, MDBContainer, MDBTypography, MDBIcon,
  MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody,
  MDBModalFooter,} from 'mdb-react-ui-kit';
import {AdvaProducts} from './productsdata';



function Resource() {
  return <p>RESOURCE</p>
}

function CategoryTab() {
  const { topicId, subId } = useParams()
  const { url, path } = useRouteMatch()

  const topic = AdvaProducts.find(({ id }) => id === topicId);

  const [pdfModal, setPdfModal] = useState(false);
  const toggleShow = () => setPdfModal(!pdfModal);

  return (
    <div>
      <MDBContainer className={'px-5 pt-4'}>
      <h2>{topic.name}</h2>
      <p className='text-capitalize'>{topic.description}</p>
      </MDBContainer>
      <div className={topic.single ? 'products-container row mb-6' : 'products-container column mb-6'}>
        {topic.resources.map((sub) => (
         
      <MDBCard key={sub.id} className={topic.single ?'product-card horizontal':'product-card'}>
      <div className="cardImage"><MDBCardImage src={sub.image} position='left' alt='...' /></div>
      <MDBCardBody className='product-detail'>
        <MDBCardTitle>{sub.name}</MDBCardTitle>
              {topic.single ? (
                <div className='details text-capitalize'>
                  <MDBCardText>{sub.details} </MDBCardText>
                  <MDBCardText className={sub.external ? 'd-block' : 'd-none'}>For more details visit 
                    <a className='d-inline-block ms-2 mt-2 rounded-0 text-info' target='blank' href={sub.link}>
                      www.haenni-scales.com</a>
                  </MDBCardText>
                  <MDBBtn className='me-auto ui_bg_primary mt-2 rounded-0'onClick={toggleShow}>View Product Details</MDBBtn>
                </div>
             
        ) : (
          <MDBRow tag='dl' className='details'>
          <MDBCol tag='dt' md='5'>
           Material
          </MDBCol>
          <MDBCol tag='dd' md='7'>
            {sub.Material}
          </MDBCol>
          <MDBCol tag='dt' md='5'>
           Application
          </MDBCol>
          <MDBCol tag='dd' md='7'>
            {sub.Application}
          </MDBCol>
          <MDBCol tag='dt' md='5'>
            Measurement Range
          </MDBCol>
          <MDBCol tag='dd' md='7'>
            {sub.MeasurementRange}
          </MDBCol>
        </MDBRow>
        )
        }
        
      </MDBCardBody>
      </MDBCard>   
         
        ))}
      </div>
      <MDBModal show={pdfModal} tabIndex='-1' setShow={setPdfModal}>
        <MDBModalDialog size='xl' scrollable>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{topic.name}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <iframe width='90%' height="900px" className="rounded-3 mx-auto d-flex justify-self-center" src={topic.pdf} allowFullScreen ></iframe>
                
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <Route path={`${path}/:subId`}>
        <Resource />
      </Route>
    </div>
  )
}

export default function Products() {
  const { url, path } = useRouteMatch()

  return (
    <div>
    <MDBContainer  className='tab-bar mx-auto gap-0'>
      <div className='mx-auto tab-row' >
        {AdvaProducts.map(({ name, id, icon }) => (
          <MDBCol size='sm' className='item p-0'>
             <NavLink to={`${url}/${id}`} activeClassName='active' className='p-2 hover-zoom' >
             <img src={icon} alt=""/>
             <h3>{name}</h3></NavLink>
          </MDBCol>
        ))}
      </div>
    </MDBContainer>
    <MDBContainer className='mt-0 bg-light pb-4'>
      <Route exact path={`${path}/:topicId`}>
        <CategoryTab />
      </Route>
    </MDBContainer>
    </div>
  )
}
