import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBCarouselElement,
  MDBCarouselCaption, MDBTypography, MDBBtn,  MDBModal,  MDBModalDialog,  MDBModalContent,  MDBModalHeader,
  MDBModalTitle,  MDBModalBody,  MDBModalFooter, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardImage, MDBInput, } from 'mdb-react-ui-kit';
import './home.css';
import icon1 from 'src/assets/svg/bl-ico-1.svg';
import icon2 from 'src/assets/svg/bl-ico-2.svg';
import icon3 from 'src/assets/svg/bl-ico-3.svg';
import icon4 from 'src/assets/svg/bl-ico-5.svg';
import icon5 from 'src/assets/svg/bl-ico-4.svg';
import slide1 from 'src/assets/images/home/banner-img-1.jpg';
import slide2 from 'src/assets/images/home/banner-img2.jpg';
import slide3 from 'src/assets/images/home/banner-img-3.jpg';
import IMG1 from 'src/assets/images/home/about-img-1.jpg';
import IMG2 from 'src/assets/images/home/about-img-2.jpg';
import FeaturedProducts from './FeaturedProducts';
import Sectors from './Sectors';
import Customers from './Customers';
import ContactForm from '../layout/ContactForm';
import EnquiryModal from '../layout/EnquiryModal';
import Certificate1 from 'src/assets/images/about/AGSI-Cer-1.jpg';
import Certificate2 from 'src/assets/images/about/AGSI-Cer-2.jpg';
const Home = () => {
 
  const [centredModal, setCentredModal] = useState(false);

  const toggleShow = () => setCentredModal(!centredModal);
  return (
    <>
    <EnquiryModal show={centredModal} setShow={setCentredModal} toggleShow= {toggleShow} ></EnquiryModal>
     <MDBCarousel showControls>
      <MDBCarouselInner>
        <MDBCarouselItem className='active'>
          <MDBCarouselElement className='w-lg-100 w-md-auto' src={slide1} alt='...' />
          <MDBCarouselCaption className='d-sm-block'>
            <div className="left">
              <hr className='top' />
            <h5 className='caption'>
             Clever <span className="text-lg blue">ENGINEERING</span> <br />
             <span className='blue'>Powering Your </span> <span className="text-lg"> Products</span>
            </h5>
            <p>We deliver reliability and rock solid dependability</p>
            <hr className='bottom' />
            </div>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem>
        <MDBCarouselElement className='w-lg-100 w-md-auto' src={slide2} alt='...' />
          <MDBCarouselCaption className='d-sm-block'>
            <div className="right">
              <hr className='top' />
            <h5 className='caption'>
              Manufacturing <span className="text-lg blue">Precision</span> <br />
             <span className='blue'> Distributing</span> <span className="text-lg">Quality</span>
            </h5>
            <p>An ENGINEERING CO-OPERATION Powering Your Products</p>
            <hr className='bottom' />
            </div>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem>
          <MDBCarouselElement className='w-lg-100 w-md-auto' src={slide3} alt='...' />
          <MDBCarouselCaption className='d-sm-block'>
            <div className="left">
              <hr className='top' />
            <h5 className='caption'>
            We represent <span className="text-lg blue"> Worldclass </span> <br />
             <span className='blue'>Swiss Products</span> <span className="text-lg"> By Haenni</span>
            </h5>
            <p className='text-capitalize'>Haenni Instruments Inc. India & SRILANKA market</p>
            <hr className='bottom' />
            </div>
          </MDBCarouselCaption>
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
  <MDBContainer fluid className='content-wraper bg-white p-0 mt-0 '>
  <section id='About' className='pt-1 bg-white mb-0'>  
  <div className="container-xl d-flex justify-content-center mt-1">
    <div className="about-container justify-content-lg-around flex-md-column-reverse ">
      <div className="contents ui_bg_mid ">
        <div className="heading">
        <h1 className="years ui_text_secondery me-lg-4">7</h1>
        <div className="d-inline-block float-right p-2 mt-lg-3"> 
          <h2 className="ui_text_highlight">Years of</h2>
          <h1>Quality & Assurance.</h1>
          </div>
        </div>
          <p className="me-lg-5 my-lg-3">
          Since its inception in the year 2015, ADVA_HAENNI, has a singular goal in mind, 
          to create the highest quality products designed exclusively for our global customers. 
          <br />We aim to strive for the best for our esteemed clients giving them world-class service 
          along with top standards.
          </p>
          <Link className="btn ui_bg_highlight rounded-0 my-lg-3 mb-lg-5" to='/company' role="button"> Read More </Link>
        </div>
       
      <div className="images ">
        <img className="layring_shadow" src={IMG1} alt=""/>
        <img className="img-top layring_shadow" src={IMG2} alt=""/>
      
      </div>
    </div> 
          </div> 
          <div className='bg-white bg-opacity-75'>
      <MDBContainer size='xxl' className='pt-5 quality-section border-bottom'>
        <MDBRow center middle className='pb-5'>
          <MDBCol className='order-lg-1 order-md-2' lg={3} md={3} sm={12}>
          {/* <h4 className='text-left ui_text_primary my-4'>We are AGSI Certified!</h4> */}
            <MDBCard className='rounded-2'>
              <MDBCardImage className="img-fluid rounded-2" src={Certificate1} alt=""/>
            </MDBCard>
            </MDBCol>
          <MDBCol  className='order-lg-2 order-md-1' lg={6} md={12} sm={12}>
          <h4 className='text-left ui_text_primary h4 container my-2'>Quality Manufacturing is <span className='h3 ui_text_coral'> A System </span><br/> And  <span className='h3 ui_text_coral'>We Implement </span> It</h4>
          <MDBTypography tag='div' className='lead text-align-justify container w-responsive pt-1'> We are AGSI Certified Manufacturing Company! We have developed a Quality Management Service which aims to ensure that the quality of products or services meets -or exceeds-customer expectations.<br/>
We understand customer's accurate requirements and specifications of each part or product . We then implement n monitor  for each production and quality checking process .
<br/>We are committed to continual improvement of Quality Management System .</MDBTypography>
          </MDBCol>
          <MDBCol className='order-lg-3 order-md-3' lg={3} md={3} sm={12}>
          {/*<h4 className='text-left ui_text_primary my-4'>Our Quality Policy</h4>*/}
            <MDBCard className='rounded-2'>
              <MDBCardImage className="img-fluid rounded-2" src={Certificate2} alt=""/>
            </MDBCard>
          </MDBCol>
          </MDBRow>
         
            </MDBContainer>
           
  <div className='container-xl Business-lines mx-auto gap-1 mt-2 mb-0'>
  <MDBRow className='mx-auto row' >
        <MDBCol size='sm' className='item'>
      <Link to='/products/product-manufacturing' className='text-center  p-2 hover-zoom' style={{ maxWidth: '22rem' }}>
      <img src={icon1} alt=''/>
    <h3 className='text-center'>Product <br/>Manufacturing</h3>
    </Link>
    </MDBCol>
    <MDBCol size='sm' className='item align-items-md-center'>
      <Link to='products/accessories' className='text-center p-2 hover-zoom' >
       <img src={icon2} alt=''/>
      <h3 className='text-center'>Assembles <br/> & Accessories</h3>
    </Link></MDBCol>
    <MDBCol size='sm' className='item'>
      <Link  to='products/machined-components' className='text-center p-2 hover-zoom' style={{ maxWidth: '22rem' }}>
      <img src={icon3} alt=''/>
      <h3 className='text-center'>Machined Parts <br/> & Components</h3>
    </Link></MDBCol>
    <MDBCol size='sm' className='item'>
      <Link to='products/sheetmetal-parts' className='text-center p-2 hover-zoom' style={{ maxWidth: '22rem' }}>
      <img src={icon4} alt=''/>
      <h3 className='text-center'>Sheet Metal Parts <br/> & Components</h3>
    </Link></MDBCol>
    <MDBCol size='md' className='item align-items-center'>
      <Link to='products/haenni-products' className='text-center p-2 hover-zoom' style={{ maxWidth: '22rem' }}>
      <img src={icon5} alt=''/>
      <h3 className='text-center'>HAENNI <br/>Products</h3>
    </Link></MDBCol>
    </MDBRow>
            </div> 
 </div>          
</section>
  
  <section id="Featured" className='bg-white'>
  <MDBContainer fluid className="p-0">
    <div className="featured_container">
      <MDBTypography tag='div' className='lead container'>
      <h2 className='text-center ui_text_highlight mt-4'>Featured Products</h2>
      <h6 className='subtext mb-5 text-light text-uppercase'>Understanding customer requirements thoroughly and crafting products which offer customized, modern and time-saving solutions.</h6>
      </MDBTypography>
    <FeaturedProducts/>
    <MDBContainer className='p-4 d-flex flex-column flex-md-row justify-content-around justify-content-md-center gap-3 align-items-center '>
          <h3 className='text-white caption text-md-start'>Looking For A Custom Made Component For your Product?</h3>
          <a className='btn btn-outline-light rounded-0 ms-lg-auto getQuote' onClick={toggleShow} role='button'>Get Quote</a>
    </MDBContainer>
    </div>
  </MDBContainer>
        </section>

  <section id='Sectors'className="shadow-inset py-3 Sectors_container mt-0">
  <MDBContainer breakpoint='xl'>
  <MDBTypography tag='div' className='lead container'>
      <h2 className='text-center ui_text_primary mt-4'>Market Sectors</h2>
      <h6 className='subtext mb-5 text-dark text-uppercase'>Expanding our manufacturing set-up globally and meeting the needs of our esteemed clients with perfection.</h6>
      </MDBTypography>
      <Sectors/>
  </MDBContainer>
  </section>
  <section id='Customers' className='page-content shadow-2-strong my-1 py-4'>
  <MDBContainer breakpoint='xl' className='p-0'>
  <MDBTypography tag='div' className='lead container'>
      <h2 className='text-center ui_text_primary mt-4'>Our Valued Customers</h2>
      <h6 className='subtext mb-5 text-uppercase text-dark'>Our client lists speaks for it self ! We have the most extensive and satisfied client base 
        from different sectors.  <br /> Our Valued Customers are <span className=' ui_text_coral'>Government Organizations, HEAVY VEHICLE & Tyre Manufacturers.</span></h6>
      </MDBTypography>
      <Customers/>
  </MDBContainer>
          
  </section>
  <section id="connect" className='my-0 py-0 overflow-hidden'>
  <ContactForm/>
  </section>
  </MDBContainer>

  </>
  );
}
export default Home;