import React from 'react'

export default function () {
  return (
      <div>
          <div className='h-100 my-5 py-5 d-flex align-items-center flex-column'>
        <h3 className='ui_text_coral my-2 text-center mx-auto'>Sorry We Could Not Find The Requested Page ! </h3>
        <h5 className='text-center mx-auto'>Please check the if the url is correct!</h5>
          </div>
    </div>
  )
}
