import React from 'react';
import { MDBContainer, MDBIcon } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
export default function Sectors() {
    return (
        <MDBContainer>
              <Link to='/solutions'>     
            <div className="Sectors_container">
         
        <div className="item hover-shadow">
                <h6><MDBIcon fas icon="link" size="4x"  className="ui_text_primary"  /></h6>
                <h5 className="title">Process Instruments</h5>
            </div>
            <div className="item hover-shadow">
                <h6><MDBIcon fas icon="microchip" size="4x"  className="ui_text_primary" /></h6>
                <h5 className="title">Sensors</h5>
            </div>
            <div className="item hover-shadow ">
                <h6><MDBIcon size="4x" fas icon="diagnoses" className="ui_text_primary" /></h6>
                <h5 className="title">Pharmacy</h5>
            </div>
            <div className="item  hover-shadow">
                <h6><MDBIcon size="4x" fas icon="carrot" className="ui_text_primary" /></h6>
                <h5 className="title">Food</h5>
            </div>
            <div className="item hover-shadow">
                <h6><MDBIcon size="4x" fas icon="road" className="ui_text_primary" /></h6>
                <h5 className="title">Transport</h5>
            </div>
            <div className="item hover-shadow">
                <h6><MDBIcon size="4x" fas icon="truck-loading" className="ui_text_primary" /></h6>
                <h5 className="title">Heavy vehicles</h5>
            </div>
                </div>
                
            </Link>
    </MDBContainer>
    );

}
