import React from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBRow, MDBCol, MDBBtn, MDBContainer, MDBCardImage } from 'mdb-react-ui-kit';

import HeroImg from 'src/assets/images/sectors/page-banner.jpg';
import sector1 from 'src/assets/images/sectors/process-instrument2.jpg';
import sector2 from 'src/assets/images/sectors/sectors-2.jpg';
import sector3 from 'src/assets/images/sectors/sectors-4.jpg';
import sector4 from 'src/assets/images/sectors/sectors-4.jpg';
import sector5 from 'src/assets/images/sectors/sectors-5.jpg';
import sector6 from 'src/assets/images/sectors/sectors-6.jpg';

import { Link, useRouteMatch, useParams } from 'react-router-dom';

var ReactRotatingText = require('react-rotating-text');
const Solutions = () =>{
  return (
    <>
    <MDBContainer fluid className='p-0 m-0'>
    <div  className='bg-image PageTital'>
    <img src={HeroImg} className='img-fluid' alt='Sample' />
      <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
        <div className='d-flex flex-column justify-content-center align-items-center h-100'>
          <h3 className='sub-text ui_text_coral'> We provide </h3>
        <h3 className='text-white ui_text_coral caption text-center'>   
        <span className='ui_text_highlight'>  
         <ReactRotatingText cursor = {false} eraseMode ='overwrite' 
          items={['Multifunctional', ' Extensive', ' Optimal']} />
        </span> Solutions.</h3>
        </div>
      </div>
      </div>
    </MDBContainer>
    <MDBContainer className="content-wraper pb-5">
      <MDBRow className='cardDeck-main g-5 align-items-lg-stretch'>
        <MDBCol sm='6' className=' align-content-lg-stretch'>
        <MDBCard className='bg-transparent shadow-0 hover-overlay rounded-0'>
          <MDBCardImage src={sector1} alt='...' fluid /> 
          <MDBCardBody className='d-flex flex-column align-self-stretch'>
            <MDBCardTitle className='ui_text_coral'>Process Instruments</MDBCardTitle>
            <MDBCardText className='mt-2 text-capitalize'>
            We provide Cost effective supply of Parts + assemblies & complete Product as per Customer design for Process Instrumentation & automation.									
            </MDBCardText>
              </MDBCardBody>
              <div className='mask' style={{ backgroundColor: 'rgba(205 209 210 / 0.2)' }}></div>
        </MDBCard>
        </MDBCol>
        <MDBCol sm='6'><MDBCard className='bg-transparent shadow-0 hover-overlay rounded-0'>
          <MDBCardImage src={sector2} alt='...' fluid /> 
          <MDBCardBody className='d-flex flex-column align-self-stretch '>
            <MDBCardTitle className='ui_text_coral'>Sensors - Factory Automation</MDBCardTitle>
            <MDBCardText className='mt-2 text-capitalize'>
            Cost effective supply for Parts + assemblies and Reliable raw material with Traceability as per EN 10204
            </MDBCardText>
            </MDBCardBody>
            <div className='mask' style={{ backgroundColor: 'rgba(205 209 210 / 0.2)' }}></div>
        </MDBCard>
        </MDBCol>
        <MDBCol sm='6'>
          <MDBCard className='bg-transparent shadow-0 hover-overlay rounded-0'>
          <MDBCardImage src={sector3} alt='...' fluid />
        
          <MDBCardBody className='d-flex flex-column align-self-stretch'>
            <MDBCardTitle className='ui_text_coral'>Food & Pharma industries</MDBCardTitle>
            <MDBCardText className='mt-2 text-capitalize'>
            Reliable stainless steel from manufacture with Traceability. Cost effective Parts made on high tech CNC & VMC.	
            </MDBCardText>
          </MDBCardBody>
          <div className='mask' style={{ backgroundColor: 'rgba(205 209 210 / 0.2)' }}></div>
          </MDBCard>
        </MDBCol>
        <MDBCol sm='6'className='align-items-stretch'>
        <MDBCard className='bg-transparent shadow-0 hover-overlay rounded-0' >
        <MDBCardImage src={sector5} alt='...' fluid />  
       
          <MDBCardBody className='d-flex flex-column align-self-stretch'>
            <MDBCardTitle className='ui_text_coral'>Heavy vehicle & Transport</MDBCardTitle>
            <MDBCardText className='mt-2 text-capitalize'>
            We Represent A SWISS WHEEL LOAD SCALE for sales & service for PAN INDIA & SRILANKA 
            </MDBCardText>
          </MDBCardBody>
          <div className='mask' style={{ backgroundColor: 'rgba(205 209 210 / 0.2)' }}></div>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer></>
  );
}
export default Solutions;