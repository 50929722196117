import React from 'react';
import {MDBContainer  } from 'mdb-react-ui-kit';
import {CustomerList} from "src/data";
export default function Customers() {
    return (
    <MDBContainer>
        <div className="Costomers_container mt-4 mb-5">
        {CustomerList.map((c)=> (
            <div key={c.id} className="item">
            <img className="p-0" src={c.VcLogo} alt={c.title}/>                
            </div>
        ))}   
        </div>
    </MDBContainer>
    );

}
