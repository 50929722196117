import React, { Component } from 'react';  
import { BrowserRouter as Router, Route, Redirect, Switch, useRouteMatch, useParams } from 'react-router-dom';  
import PublicRoutes from "./routes/PublicRoutes";
import { useLocation } from 'react-router';
import Home from "./componants/home/Home";
import Company from "./componants/company/Company-2";
import Businesslines from "./componants/businesslines/Businesslines";
import Solutions from './componants/solutions/Solutions';
import Products from './componants/businesslines/Products'
import Support from './componants/support/Support';
import 'src/css/layout.css';
import NotFound from './componants/layout/NotFound';
import ScrollToTop from './ScrollToTop';
class App extends Component {  
  
  render() {  
    return (  
      <>
        <Router>
          <ScrollToTop>
        <Switch>
          <Route exact path="/"><Redirect to="/home"/></Route>
          <PublicRoutes path="/company" component={Company} />         
           <PublicRoutes path="/businesslines" exact component={Businesslines} />
          <PublicRoutes path="/solutions" component={Solutions} />
            <PublicRoutes path="/products" component={Products} />
            <PublicRoutes path="/support" component={Support} />  
            <PublicRoutes path="/home" component={Home} />  
            <PublicRoutes path="/error" component={NotFound} /> 
            <Route path="*"><Redirect to="/error"/></Route>
            </Switch>
            </ScrollToTop>
      </Router>
      </>
    );
  }
}
export default App;