import React, { useState, useEffect, useRef } from 'react';
import {MDBRow, MDBCol, MDBBtn, MDBTextArea, MDBInput, MDBValidationItem, MDBValidation, MDBBadge} from 'mdb-react-ui-kit';
import emailjs from "@emailjs/browser";

const SERVICE_ID = "service_0cshptl";
const TEMPLATE2_ID = "template_k535h4a";
const PUBLIC_KEY = "jC3UHAafOdhvXZTqJ";
export default function EnquiryForm() {
  
  const [formValue, setFormValue] = useState({
    fname: '',
    userEmail: '',
    userPhone: '',    
    Industry: '',
    userRequirement:'',
    
});
  const [validated, setValidated] = useState(false);  
const [transitionAlert, setTransitionAlert] = useState(false);
const [showErrorAlert, setShowErrorAlert] = useState(false)
const [showSuccessAlert, setShowSuccessAlert] = useState(false);
const formRef = useRef();
const inputChange = (e: any) => {
  setFormValue({...formValue, [e.target.name]: e.target.value
  });
  };
  
  const doSubmit = (event) => {
    const formRef = event.currentTarget;
    
    if (formRef.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      sendEnquiry(event);         
    }
    setValidated(true);
  };

  const sendEnquiry = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE2_ID, e.target, PUBLIC_KEY)
      .then((result) => {
        setTransitionAlert(true);
        setShowSuccessAlert(true);   
        setTimeout(() => {
          setValidated(false);
          setTransitionAlert(false);
          formRef.current.reset();
          formReset();
        }, 2000);        
        console.log(result.text);
      }, (error) => {
        console.log(error.text);  
        setTransitionAlert(true);        
        setShowErrorAlert(true); 
        setTimeout(() => {
          setValidated(false);
          setTransitionAlert(false);
          formRef.current.reset();
          formReset();
        },2000);
      });  
      
  };
const formReset = () => {
  //document.getElementById('enquiry-form').classList.remove('was-validated');
 setFormValue({
  fname: '',
  userEmail: '',
  userPhone: '',    
  Industry: '',
  userRequirement:'',
 }); 
  setShowSuccessAlert(false);  
  setShowErrorAlert(false);  
  document.querySelectorAll('.ui_form .form-control.active').forEach(function (item) {
    item.classList.remove('active');                   
  });
  }

  const handleClickTransitionAlert = (event) => {
    setTransitionAlert(true);
    setShowSuccessAlert(true);
    setTimeout(() => {
      setValidated(false);
      setTransitionAlert(false);
      event.target.reset();
    }, 1700);
  };


    return (
      <div className="ui_form">
        <MDBValidation className="row row-cols-md-2 gx-3 gy-5 justify-content-center" noValidate ref={formRef}
          onSubmit={doSubmit} validated={validated} id='enquiry-form'>
          <MDBValidationItem  size='12' lg='6' className='col-lg-6  mb-md-0' feedback='Please Enter Your Full Name.' invalid >
                  <MDBInput type='text' id='FName' minLength={8} 
                    value={formValue.fname} onChange={inputChange} required
                    name='fname' contrast label='Full Name' />
          </MDBValidationItem>
          
          <MDBValidationItem  size='12' lg='6' className='col-lg-6  mb-md-0' feedback='Please Mention Your Business Industry.' invalid>
                  <MDBInput type='text' id='Industry' minLength={5} 
                    value={formValue.Industry} onChange={inputChange} required
                    name='Industry' contrast label='industry type' />
          </MDBValidationItem>

          <MDBValidationItem  size='12' lg='6' className='col-lg-6  mb-md-0' feedback='Please Provide your Email.' invalid>
                  <MDBInput type='email' id='UserEmail'
                    value={formValue.userEmail} onChange={inputChange} required
                    name='userEmail' contrast label='Email address' />
          </MDBValidationItem>
          <MDBValidationItem ol size='12' lg='6' className='col-lg-6 mb-md-0' feedback='Please Provide your Contact No.' invalid >
                  <MDBInput type='tel' pattern='[0-9]*' className='rounded-0' contrast minLength={10} maxLength={11}
                    id='userPhone' name='userPhone' label='Phone'
                    value={formValue.userPhone} onChange={inputChange} required />
                </MDBValidationItem>
          <MDBValidationItem  size='12' md='12' className='col-md-12  mb-md-0' feedback='Please Describe your Requirement in the textarea.' invalid >
                  <MDBTextArea rows={3} className='rounded-0' contrast
                     value={formValue.userRequirement} onChange={inputChange} minLength={10} 
                    id='UserRequirement' name='userRequirement' label='Describe Your Requirement' required />
                </MDBValidationItem>
                <MDBCol size='6'md={12} lg='6' className='mb-4 mb-md-0 align-content-end text-center'>
            <MDBBtn type='submit' size='block' color='danger' className='ui_bg_coral rounded-0 mx-auto'>Submit</MDBBtn>
          </MDBCol>      

        </MDBValidation>
        <div>
        {showSuccessAlert &&
          <h5 className={`text-white text-center mt-2 ${
            transitionAlert ? "d-block" : "d-none"
          }`}><MDBBadge color='success' light>Thank You! We will Get back to you!</MDBBadge></h5>
          } 
        {showErrorAlert &&
          <h5 className={`text-white text-center mt-2 ${
            transitionAlert ? "d-block" : "d-none"
          }`}>  <MDBBadge color='danger' light>Ops! Something went wrong !</MDBBadge></h5>
         }
          
          
              </div>
    </div>
    );
}
