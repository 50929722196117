import React, { useState, useRef } from 'react';
import emailjs from "@emailjs/browser";
import {
  MDBTypography, MDBBtn, MDBContainer, MDBRow, MDBCol, MDBInput,
  MDBValidationItem, MDBValidation,MDBBadge 
} from 'mdb-react-ui-kit';  
import ConfigData from "src/ConfigData.json";

const SERVICE_ID = "service_0cshptl";
const TEMPLATE_ID = "template_aoxkzv5";
const PUBLIC_KEY = "jC3UHAafOdhvXZTqJ";
export default function ContactForm() {  
  const [formValue, setFormValue] = useState({
      FullName: '',
      Email: '',
      Requirement: '',
      Phone: '',
      
  });
  const [validated, setValidated] = useState(false);
  const [transitionAlert, setTransitionAlert] = useState(false);
const [showErrorAlert, setShowErrorAlert] = useState(false)
const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const formRef = useRef();
  const onChange = (e: any) => {
    setFormValue({...formValue, [e.target.name]: e.target.value
    });
  };
 
  const formReset = () => {
   // document.getElementById('contact-form').classList.remove('was-validated');
   setFormValue({
      FullName: '',
      Email: '',
      Requirement: '',
      Phone: '',
   });
   setShowSuccessAlert(false);  
   setShowErrorAlert(false);  
    document.querySelectorAll('.ui_form .form-control.active').forEach(function (item) {
      item.classList.remove('active');                   
    });
    }
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(ConfigData.SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)    
      .then((result) => {
        setTransitionAlert(true);
        setShowSuccessAlert(true);        
        //alert("Your message has been sent."); 
        setTimeout(() => {
          setValidated(false);
          setTransitionAlert(false);
          formRef.current.reset();
          formReset();
        }, 2000);
        console.log(result.text);
          
      }, (error) => {
        setTransitionAlert(true);        
        setShowErrorAlert(true);   
        setTimeout(() => {
          setValidated(false);
          setTransitionAlert(false);
          formRef.current.reset();
          formReset();
        }, 2000);
        console.log(error.text);
      });    
    
  };
  const handleSubmit = (event) => {
    const formRef = event.currentTarget;
    
    if (formRef.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
         } else {
      //alert("Message was sent!");        
      sendEmail(event);  
    }       
    setValidated(true);
  };
    return(
  <MDBContainer fluid className="connect_bg my-0">
  <MDBContainer className='connect_container mb-0'>
    <div className="left">
            <div>
    <h3 className='text-white mt-2 sectionTitle'>Connect</h3>
    <hr className="ui_bg_secondery w-75"></hr>
    </div>
            {/* <p>For any kind of query, contact us with the details bellow.</p> */}
    <h6 className='title mt-2'>Call Us</h6>
    <p className='h6'>9769014645 <span className='mx-2 text-info'> | </span> 9511875728 <span className='mx-2 text-info'> | </span> 855197960 </p>
    <h6 className='title mt-2'>Registered Office Adress</h6>
    <p>14/1 Konark Aangan, Bibwewadi, Pune-411037 INDIA</p>
    <h6 className='title mt-2'>Workshop Address</h6>
    <p>Plot No 62/2 Chintamani Industrial Estate,<br></br> Bhidebaug Vadgaon BK. PUNE 411041</p>
    </div>
    <div className="right">
    <h3 className='sectionTitle text-light mt-2'>Send Enquiry</h3>
    <hr className="ui_bg_secondery w-25"></hr>
            <p>Want to enquire about a product? Write To US.</p>
            <div className="ui_form" >            
          <MDBValidation className="row row-cols-md-2 g-3"  noValidate ref={formRef}
          onSubmit={handleSubmit}
          validated={validated}
          id="contact-form" >
            <MDBValidationItem className='col-lg-6 col-md-12 mb-4 mb-md-0' feedback='Please Enter Your Full Name.' invalid >
                  <MDBInput type='text' id='name1' contrast label='Full Name' 
                     value={formValue.FullName} name='FullName' minLength={10} onChange={onChange} required />
            </MDBValidationItem>
            
            <MDBValidationItem className='coal-lg-6 coal-md-12 mb-4 mb-md-0' feedback='Please Mention Your Requirement.'  invalid>
              <MDBInput type='text' id='requirement' contrast label='Requirement'
              value={formValue.Requirement} name='Requirement' minLength={10} onChange={onChange} required    />
            </MDBValidationItem>

            <MDBValidationItem className='col-lg-6 coal-md-12 mb-4 mb-md-0' feedback='Please Provide A Valid Email.' invalid>
                <MDBInput type='email' id='email' contrast label='Email address'
                    value={formValue.Email} name='Email' onChange={onChange} required />
            </MDBValidationItem>
            <MDBValidationItem className='col-lg-6 col-md-12 mb-4 mb-md-0' feedback='Please Provide Your Contact No.' invalid>
                  <MDBInput type='tel' pattern='[0-9]*' id='phone'  minLength={10} maxLength={11} className='rounded-0' contrast label={'Phone'}
             value={formValue.Phone} name='Phone' onChange={onChange} required /> 
            </MDBValidationItem>
            <MDBValidationItem className='coal-lg-16 coal-md-12 mb-4 mb-md-0'>
              <MDBBtn type='submit' color='danger' className='rounded-0'>Submit</MDBBtn>
                </MDBValidationItem>                
              </MDBValidation>
              <div>
              {showSuccessAlert &&
          <h5 className={`text-white text-center mt-2 ${
            transitionAlert ? "d-block" : "d-none"
          }`}><MDBBadge color='success' light>Thank You! We will Get back to you!</MDBBadge></h5>
          } 
        {showErrorAlert &&
          <h5 className={`text-white text-center mt-2 ${
            transitionAlert ? "d-block" : "d-none"
          }`}>  <MDBBadge color='danger' light>Ops! Something went wrong! Please Try Again</MDBBadge></h5>
         }              
              </div>
        </div>
    </div>

  </MDBContainer>
    </MDBContainer>

    );
}
