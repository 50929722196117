import React from "react";
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImage, MDBBtn, MDBBadge } from 'mdb-react-ui-kit';
import {FeaturedCards} from "src/data";
export default function FeaturedProducts() {

  const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,

    responsive: [
      {
        breakpoint: 992,
        settings: {
         slidesToShow: 2,
        }
      },
      {
        breakpoint: 639,
        settings: {
         slidesToShow: 1,
        }
       }
    ]
  }

  return (
    <div className='content container mt-2'>
      <Slider {...sliderSettings}>
        {FeaturedCards.map((card, index) => (
           <MDBCard key={index} className="featuredtCard">
           <div className="bg-image hover-zoom" data-mdb-ripple-color="light">
             <MDBCardImage alt={card.title} src={card.imageSrc} fluid />
             <a href={card.link}>
              <div className="mask" style={{background:'rgba(251, 251, 251, 0.15)'}}></div>
            </a>
           </div>        
           <MDBCardBody className="text-center">
             <MDBCardTitle>{card.title}</MDBCardTitle>
             <MDBCardText>
             {card.description}
             </MDBCardText>
             <h6> 
                 {card.sectors.map((sector, index) => (
                     <MDBBadge pill key={index} className='text-dark mx-1' color="light">
                      {sector}
                     </MDBBadge>
                ))}
             </h6>
             <MDBBtn tag="a" color='link'  href={card.link} className="stretched-link rounded-0 btn-link text-lg ui-text-primary">View</MDBBtn>
           </MDBCardBody>
         </MDBCard>
          
        ))}
      </Slider>
    </div>
  )
}