import React from "react";
import { Route } from "react-router-dom";
import Layout from "src/componants/layout/Layout";

const PublicRoutes = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        }}
      />
    );
  };
export default PublicRoutes;